<template>
  <div class="scroll-container">
    <div class="slot-machine" @click="startRolling">
      <div class="slot-list" ref="slotList">
        <!--  backgroundImage: `url('${item.img}')`, -->
        <div
          v-for="(item, index) in arrayList"
          :key="index"
          class="slot-item"
          :style="{
            border: `2px solid ${item.color}`,
            color: item.color,
            boxShadow:`0px 0px 10px ${item.color}`
          }"
          :id="item.id"
        >
          <img :src="item.imageUrl" alt="" srcset="" />
          <p>{{ item.box_name }}</p>
        </div>
      </div>
      <img
        src="https://chskins.com/static/img/yinying.a1a02be.png"
        class="mask"
        alt=""
        srcset=""
      />
    </div>
    <div class="line">
      <div class="line-bar"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    delay: {
      type: Number,
      default: 0,
    },
    direction: {
      type: String,
      default: "vertical", //horizontal
    },
  },
  mounted() {
    setTimeout(() => {
      // this.startRolling();
    }, this.delay);
  },
  computed: {
    arrayList() {
      let list = [];
      for (let index = 0; index < 1; index++) {
        list = list.concat(this.list);
      }
      return this.shuffle(list);
    },
  },
  methods: {
    shuffle(arr) {
      return arr.sort(() => Math.random() - 0.5);
    },
    startRolling() {
      // 滚动速度
      let speed = 50;
      // 滚动时间
      let time = 7;
      let count = 0;
      // 随机选取目标位置 由于前面的数组已经打乱了的，所以只需要取最后第2个就是了
      let targetIndex = this.arrayList.length - 15;
      // 计算滚动的最后时长 500 是最后位置调整时长
      const scrollTime = new Date().getTime() + time * 1000 - 2000;
      let slotList = this.$refs.slotList;

      const setup = () => {
        const nowTime = new Date().getTime();
        // 判断是否达到目标位置
        if (nowTime >= scrollTime) {
          // 滚动结束，触发事件
          this.$emit("finish-rolling", this.arrayList[targetIndex + 3]);
          // 达到目的位置进行滚动
          slotList.style.transition = `all 2000ms ease-out`;
          // 在最终停下来的时候的位置
          slotList.style.transform = `translateX(-${targetIndex * 204}px)`;
        } else {
          count += speed;
          slotList.style.transition = `none`;
          slotList.style.transform = `translateX(-${count % (204 * 7)}px)`;
          window.requestAnimationFrame(setup);
        }
      };
      setup();
    },
  },
};
</script>

<style lang="less" scoped>
@widthItem: 200px;
@heightItem: 270px;
.scroll-container {
  width: 1510px;
  height: 265px;
  box-sizing: border-box;
  background-size: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url("https://chskins.com/static/img/zhuanpan.3c1a1ec.png");
  .line {
    display: block;
    position: absolute;
    height: 100%;
    bottom: 0;
    width: 23.5px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    left: 50%;
    transform: translateX(-9px);
    &::before {
      display: block;
      position: absolute;
      content: "";
      border: 10.5px solid transparent;
      top: 0;
      border-top-color: #ff9000;
    }
    &::after {
      display: block;
      position: absolute;
      content: "";
      border: 10.5px solid transparent;
      bottom: 0;
      border-bottom-color: #ff9000;
    }
    &-bar {
      width: 5px;
      height: 100%;
      background-color: #fff;
      -webkit-box-shadow: 0 6px 14px 0 #ff2e05, 0 6px 24px 0 #ff2e05;
      box-shadow: 0 6px 14px 0 #ff2e05, 0 6px 14px 0 #ff2e05;
    }
  }
}
.slot-machine {
  width: calc((@widthItem + 4px) * 7);
  height: calc(@heightItem - 100px);
  user-select: none;
  overflow: hidden;
  position: relative;
  margin-top: 3px;
  margin-left: 8px;
  .mask {
    position: absolute;
    top: -5%;
    left: -5%;
    height: 110%;
    width: 110%;
  }
}
.slot-list {
  position: absolute;
  top: 0;
  left: 0;
  width: 1200px;
  height: 100%;
  display: flex;
  white-space: nowrap;
}
.slot-item {
  height: 100%;
  width: @widthItem;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-size: 100% 100%;
  box-sizing: border-box;
  margin: 0 2px;
  img {
    width: 70%;
  }
  p {
    font-size: 15px;
    width: @widthItem;
    height: 14px;
    text-align: center;
  }
}
</style>

<template>
  <div class="test">
    <div v-if="list.length" class="horizontal">
      <SlotMachineH
        :list="list"
        @finish-rolling="finishRolling"
        :key="index"
        :delay="index * 200"
        v-for="index in 3"
        :style="{ marginBottom: '10px' }"
      />
    </div>
    <!-- <div v-if="list.length" class="xx">
        <img
        class="top"
        src="../../assets/img/machine-top.png"
        alt=""
        srcset=""
      />
      <img
        class="bottom"
        src="../../assets/img/dibu.png"
        alt=""
        srcset=""
      />
      <SlotMachineV
        :list="list"
        @finish-rolling="finishRolling"
        :key="index"
        :delay="index * 200"
        v-for="index in 5"
      />
      <img
        class="center-line"
        src="../../assets/img/shuxian.png"
        alt=""
        srcset=""
      />
    </div> -->
  </div>
</template>

<script>
import SlotMachineH from "./slot-machine-horizontal.vue";
import SlotMachineV from "./slot-machine.vue";
export default {
  name: "Test",
  components: { SlotMachineH, SlotMachineV },
  data() {
    return {
      list: [],
    };
  },

  created() {
    // let param = {
    //   player_id: localStorage.getItem("id"),
    //   box_id: 22,
    //   num: 1,
    // };
    // this.$axios
    //   .post("/index/Box/buyBoxfake", this.$qs.stringify(param))
    //   .then((res) => {
    //     if (res.data.status == 1) {
    //     }
    //   });

    let param = {
      page: 1,
      pageSize: 100,
    };
    this.$axios
      .post("index/Box/lately", this.$qs.stringify(param))
      .then((res) => {
        if (res.data.status == 1) {
          this.list = res.data.data.list;
        }
      });
  },
  methods: {
    finishRolling(item) {
      console.log(item.box_name, item.id);
    },
  },
};
</script>

<style lang="less" scoped>
.test {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #212126;

  .horizontal {
    width: 100vw;
    padding: 35px 0 15px 0;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    transform: scale(0.6);

    .center-line {
      position: absolute;
      top: 20px;
      width: 15px;
      left: 50%;
      transform: translateX(-50%);
      height: calc(100% - 30px);
    }
  }
}
.xx {
  display: flex;
  width: 350px;
  margin: 50px 0;
  //   background: url("../../assets/img/dingbu.png") no-repeat 50% 0%,
  //     url("../../assets/img/dibu.png") no-repeat 50% 100%;
  padding: 10px 0 5px 0;
  position: relative;
  display: flex;
  justify-content: center;
  .center-line {
    position: absolute;
    top: -40px;
    width: 10px;
    left: -15px;
    transform: rotate(90deg) translate(-50%, -50%);
    height: calc(100vw - 20px);
  }
  .top {
    position: absolute;
    top: 0px;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
  }
  .bottom {
    position: absolute;
    bottom: 0px;
    width: 100%;
    left: 0;
  }
}
</style>
